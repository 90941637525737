.singinbg {
    background-image: url('../Assests/bgimage.webp');
    background-size: cover;

}

.bgclr {
    background-color: rgba(255, 255, 255, 1);
}

.tablebg {
    background-color: rgba(255, 255, 255, 0.9);
    border: 2px solid white;
    padding: 10px;
    padding-top: 0px;
}

.tablebg th {
    border-bottom: 2px solid rgb(216, 215, 215);
}

.tablebg td {
    border-bottom: 2px solid rgba(216, 215, 215, 0.5);
}

/* .tablebg input{
    background-color: transparent;
    outline: 2px solid transparent;
    outline-offset: 2px;

} */
.tablebg th,
td {
    padding: 10px;
    /* text-align: left; */
    vertical-align: top;
}

.tablebg th {
    /* text-align: center; */
    padding-left: 12px;
}

.txtclr {
    color: #718EBF;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.btngrd {
    background: rgb(6, 7, 112);
    background: linear-gradient(180deg, rgba(6, 7, 112, 1) 0%, rgba(47, 95, 222, 1) 66%);
}

.btngrd2 {
    background: rgba(47, 95, 222, 1);
    background: linear-gradient(180deg, rgba(47, 95, 222, 1) 0%, rgba(6, 7, 112, 1) 66%);
}

.rowbg {
    background-color: rgba(113, 142, 191, 0.2);
}

.colbg {
    background-color: rgba(113, 142, 191, 0.3);
}

.formscroll::-webkit-scrollbar {
    width: 8px;
    /* Set the width of the scrollbar */
}

.formscroll::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Track color */
    border-radius: 10px;
}

.formscroll::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Scrollbar color */
    border-radius: 10px;
    /* Rounded edges */
    border: 2px solid #f1f1f1;
    /* Adds space between the track and scrollbar */
}

.formscroll::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* Darker on hover */
}