.poppins {
  font-family: "Poppins", sans-serif;
}

.transi {
  transition: 0.5s;
}
.sidebarbg{
  background-image: url('./Assests/sidebar.png');
  background-position: center bottom;
  background-size: cover;
}
.bgSide{
  background-image: url('./Assests/bgTemplate.png');
  background-position: center ;
  background-size: cover;
}
*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}